import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../environments/environment";

@Injectable()
export class AuthService {
  authToken: any;
  token: any;
  baseUrl = environment.test;
  // headers1 = { headers: { Authorization: "Bearer " + this.token } };

  constructor(private http: HttpClient) {}

  login(data) {
    return this.http
      .post(this.baseUrl + "radiologist/login", data)
      .toPromise()
      .then(res => res);
  }

  forgotPassword(data) {
    return this.http
      .post(this.baseUrl + "user/forgot-password", data)
      .toPromise()
      .then(res => res);
  }

  casesList(data?: any, filter?: any) {
    let headers =  { params: data, headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "radiologist/case-list",filter,  headers).toPromise().then(res => res);
  }

  search(data?: any){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "radiologist/case-list",data,  headers).toPromise().then(res => res);
  }

  test(){
    let headers =  { headers: { Authorization:  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYzYTlkNWUyZjBhZDU4NmM2MGNmN2Q0NCIsImVtYWlsIjoiaW5mb3N0cmlkZUB5b3BtYWlsLmNvbSIsInR5cGUiOiJvcmdhbml6YXRpb24ifSwiZXhwIjoxNjc1NDM2OTE1LCJpYXQiOjE2NzI4NDQ5MTV9.w9iIUaXdL9BqG0LVlo1x4T6QDI0pKKM0t6vNR-QUsx0' } };
    return this.http
    .get("http://a6b5-49-249-66-182.in.ngrok.io/org/job-application-request",  headers).toPromise().then(res => res);
  }

  // Get all notifications
  getNotifications(){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/get-notification", headers).toPromise().then(res => res); 
  }

  // Update unread notification to read 
  updateNotifications(){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/update-notification", headers).toPromise().then(res => res); 
  }

  getProfile(){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "radiologist/profile", headers).toPromise().then(res => res);
  }

  editProfile(data){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .put(this.baseUrl + "radiologist/edit-profile", data, headers).toPromise().then(res => res);
  }

  statusChange(id,data){
    console.log(localStorage.getItem("id_token"), data)
    let headers =  {headers: { Authorization:  localStorage.getItem("id_token") } };
    try {
      return this.http
      .post(this.baseUrl + "cases/update-case-status/" + id, data, headers).toPromise().then(res => res).catch(e => console.log(e));
    } catch (error) {
      console.log(error)
    }
  }

  caseSummary(id){
    let data = {_id: id}
    let headers =  {headers: { Authorization:  localStorage.getItem("id_token") } };
      return this.http
      .post(this.baseUrl + "cases/cases-list", data, headers).toPromise().then(res => res);
  }
  
  postComment(data){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http.post(this.baseUrl + "cases/add-comment", data, headers).toPromise().then(res => res);
  }

  getComments(id){
    let data =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/comment/" + id, data).toPromise().then(res => res);
  }

  uploadFile(id, file){
    let data =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .put(this.baseUrl + "cases/edit-case/" + id,file, data).toPromise().then(res => res);
  }

  storeUserData(token: string) {
    localStorage.setItem("id_token", token);
    this.authToken = token;
  }

 
  loadToken() {
    const token = localStorage.getItem("id_token");
    this.authToken = token;
    return localStorage.getItem("id_token");
  }

  loggedIn() {
    return this.loadToken() !== null;
  }

  logout() {
    this.authToken = null;
    localStorage.clear();
  }
}
