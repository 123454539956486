import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: 'login', loadChildren: './login/login.module#LoginModule'},
  { path: 'forgotpassword', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule'},
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard]},
  { path: 'profile', loadChildren: './radiologist-profile/radiologist-profile.module#RadiologistProfileModule', canActivate: [AuthGuard]},
  {path: 'caseSummarry/:id', loadChildren: './case-summary/case-summary.module#CaseSummaryModule', canActivate: [AuthGuard]},
  { path: '', redirectTo: '/login', pathMatch: 'prefix' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
